import React from "react";
import Header from "../components/header/Header";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Layout from "../components/layout/Layout";
import TextileImgWool from "../assets/Textile Trading/wool.jpg";
import TextileImgSilk from "../assets/Textile Trading/SILk.jpg";
import TextileImgCotton from "../assets/Textile Trading/cotton.jpg";
import TextileImgLinen from "../assets/Textile Trading/linen.jpg";
import TextileImgPolyester from "../assets/Textile Trading/polyester.jpg";
import TextileImgNylon from "../assets/Textile Trading/nylon.jpg";
import PageContainer from "../components/layout/PageContainer";

const TextileTrading = () => {
  return (
    <Layout>
      <PageContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            padding: "5px 20px",
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "36px", fontWeight: "bold" }}
            >
              Partner in organic farming
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
              mt: "50px",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}
            >
              With decades of experience in the agricultural sector, we are
              proud to be a partner in organic farming. We understand the
              challenges you face and provide the expertise and products needed
              to develop and maintain a successful and sustainable organic
              farming business.<br></br>
              An industry pioneer in innovation and sustainability,our company
              is a dominant player in agricultural sector, offering many
              agriculture products and services to local and export markets.
              farmers benefit from a diverse portfolio of agri products and
              agri-solutions, including seed and planting materials, fertilizer,
              crop protection products, household and industrial pest control
              services and atc . Our carefully bred seeds also offer resilience
              and lower maintenance characteristics. The our company range of
              seeds is ideal for planting in main fields and broadacre crops
              used for fodder.
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    </Layout>
  );
};

export default TextileTrading;
