import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header/Header";
import Layout from "./components/layout/Layout";
import { Box, Button, Container, Typography } from "@mui/material";
import BackgroundImg from "./assets/ab.jpg";
import PageContainer from "./components/layout/PageContainer";
import { Link } from "react-router-dom";

function App() {
  return (
    <Layout>
      <Box
        sx={{
          minHeight: { xs: "400px", sm: "800px" },
          backgroundImage: `url(${BackgroundImg})`,
          backgroundRepeat: "repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: { xs: "100px", sm: "100px" },
          mt: "-50px",
        }}
      >
        <Box
          sx={{
            width: { xs: "300px", sm: "900px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "30px",
            padding: "20px",
            backgroundColor: "rgba(255,255,255,.2)",
            backdropFilter: "blur(10px)",
          }}
        >
          {/* <Typography variant='h1' sx={{ fontSize: { xs: "36px", sm: "116px" }, textAlign: "center", fontWeight: "600" }}>Koil Trading</Typography> */}
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: "18px", sm: "36px" }, textAlign: "center" }}
          >
            TAZAMONI SEYED MOHAMMAD HASSAN ABTAHI AND SHORAKA
          </Typography>
        </Box>
      </Box>
      <PageContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "36px", fontWeight: "bold" }}
            >
              About Us
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="caption" sx={{ fontSize: "16px" }}>
              At TAZAMONI SEYED MOHAMMAD HASSAN ABTAHI AND SHORAKA, our goal is
              to provide you with the best services and products in the fields
              of food, fertilizer and agricultural products. Our products come
              exclusively from controlled cultivation, are subject to the
              strictest controls and impress with their excellent quality. We
              understand the special requirements involved in the production of
              know about the high standards that are required from the additives
              used. That is why all our products meet all legal requirements and
              meet the high demands of the manufacturing industry.
              <br></br>
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    </Layout>
  );
}

export default App;
