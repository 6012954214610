import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px 10px" }}>
            <Typography variant='caption' sx={{ color: "#000000", fontSize: "12px" }}>Copyright@ 2020 All rights reserved</Typography>
        </Box>
    )
}

export default Footer