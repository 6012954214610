import React from "react";
import Header from "../components/header/Header";
import { Box, Button, Container, Typography } from "@mui/material";
import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";
import HydraulicImg from "../assets/Our Business.  Heavy machinery spare parts trading.png";
import fertilizersImg from "../assets/1. fertilizers.jpg";
import PartnerImg from "../assets/2.Partner in organic farming.jpg";
import foodImg from "../assets/3. food.jpg";
import materialsImg from "../assets/Raw Material Trading/raw materials trading.png";
import TextileImg from "../assets/Textile Trading/Textile Trading.jpg";
import PageContainer from "../components/layout/PageContainer";

const OurBusiness = () => {
  const services = [
    {
      title: "Fertilizer",
      caption:
        "The fertilizer industry transforms millions of tons of air, natural gas and mined ores into plant nutrition products based on the three essential nutrients nitrogen, phosphorus and potassium. But how are fertilizers produced?",
      imageSrc: fertilizersImg,
      route: "/business/fertilizer",
    },
    {
      title: "Partner in organic farming",
      caption:
        "With decades of experience in the agricultural sector, we are proud to be a partner in organic farming. We understand the challenges you face and provide the expertise and products needed to develop and maintain a successful and sustainable organic farming business.",
      imageSrc: PartnerImg,
      route: "/business/partner",
    },
    {
      title: "Food",
      caption: `Wholesale distributor of natural raw materials: food additives, food raw materials, natural sweetening substances, food-grade vegetable oils.`,
      imageSrc: foodImg,
      route: "/business/food",
    },
  ];
  return (
    <Layout>
      <PageContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "36px", fontWeight: "bold" }}
            >
              Our Business
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
              mt: "50px",
            }}
          >
            {services.map((item) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "50px",
                    border: "1px solid #ec0000",
                    padding: "20px 30px",
                    transition: "0.2s",
                    textDecoration: "none",
                    position: "relative",
                    "&:hover": {
                      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, .2);",
                    },
                  }}
                  component={Link}
                  to={item.route}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "20%" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt="About image"
                      src={item.imageSrc}
                      sx={{ width: "100%", borderRadius: "5px" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "80%" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "20px",
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "rgb(50 50 50)",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}
                    >
                      {item.caption}
                    </Typography>
                  </Box>
                  <Box sx={{ position: "absolute", right: "5px", bottom: "5px" }}>
                    <Button>More</Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </PageContainer>
    </Layout>
  );
};

export default OurBusiness;
