import React, { useCallback, useState } from "react";
import Header from "../components/header/Header";
import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";
import ContactImg from "../assets/contact us.jpg";
import PageContainer from "../components/layout/PageContainer";
import { useForm } from "react-hook-form";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = useCallback(async (data: any) => {
    setLoading(true);
    console.log(data);
  }, []);
  return (
    <Layout>
      <PageContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Box
            component="img"
            alt="About image"
            src={ContactImg}
            sx={{ width: "100%", borderRadius: "50px" }}
          />
          {/* <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            About Us
                        </Typography>
                    </Box> */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgb(248 248 248)",
                padding: "20px 50px",
                borderRadius: "50px",
                flexGrow: 2,
              }}
            >
              <Typography
                variant="h2"
                sx={{ color: "rgb(91 91 91)", fontSize: "36px" }}
              >
                Send a Message
              </Typography>
              <Box sx={{ mt: "50px", backgroundColor: "rgb(248 248 248)" }}>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <Box mb={errors?.name?.message ? 3 : 4}>
                    <TextField
                      variant="outlined"
                      label="Name"
                      autoComplete="false"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ec0000",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "rgb(150 150 150)", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "rgb(90 90 90)", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "#ec0000",
                          },
                        },
                        "& label": {
                          color: "rgb(90 90 90)",
                        },
                        "& label.Mui-focused": {
                          color: "#ec0000",
                        },
                      }}
                      {...register("name", {
                        required: {
                          value: true,
                          message: `Name is Required`,
                        },
                      })}
                    />
                    {errors?.name && (
                      <Alert
                        variant="filled"
                        severity="error"
                        sx={{
                          mt: 2,
                        }}
                      >
                        {errors.name.message?.toString()}
                      </Alert>
                    )}
                  </Box>
                  <Box mb={errors?.email?.message ? 3 : 4}>
                    <TextField
                      variant="outlined"
                      label="Email"
                      autoComplete="false"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ec0000",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "rgb(150 150 150)", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "rgb(90 90 90)", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "#ec0000",
                          },
                        },
                        "& label": {
                          color: "rgb(90 90 90)",
                        },
                        "& label.Mui-focused": {
                          color: "#ec0000",
                        },
                      }}
                      {...register("email", {
                        required: {
                          value: true,
                          message: `Email is Required`,
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: `Email is not valid`,
                        },
                      })}
                    />
                    {errors?.email && (
                      <Alert
                        variant="filled"
                        severity="error"
                        sx={{
                          mt: 2,
                        }}
                      >
                        {errors.email.message?.toString()}
                      </Alert>
                    )}
                  </Box>
                  <Box mb={errors?.message?.message ? 3 : 4}>
                    <TextField
                      variant="outlined"
                      label="message"
                      autoComplete="false"
                      multiline
                      rows={4}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ec0000",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "rgb(150 150 150)", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "rgb(90 90 90)", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "#ec0000",
                          },
                        },
                        "& label": {
                          color: "rgb(90 90 90)",
                        },
                        "& label.Mui-focused": {
                          color: "#ec0000",
                        },
                      }}
                      {...register("message", {
                        required: {
                          value: true,
                          message: `Message is Required`,
                        },
                      })}
                    />
                    {errors?.message && (
                      <Alert
                        variant="filled"
                        severity="error"
                        sx={{
                          mt: 2,
                        }}
                      >
                        {errors.message.message?.toString()}
                      </Alert>
                    )}
                  </Box>
                  <LoadingButton
                    sx={{
                      backgroundColor: "#000",
                      color: "#fff",
                      borderRadius: "50px",
                      py: "10px",
                      px: "40px",
                      "&:hover": { backgroundColor: "#000" },
                    }}
                    fullWidth
                    variant="contained"
                    type="submit"
                    loading={loading}
                  >
                    Send a Message
                  </LoadingButton>
                </form>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "rgb(248 248 248)",
                padding: "20px 50px",
                borderRadius: "50px",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="h2"
                sx={{ color: "rgb(91 91 91)", fontSize: "36px" }}
              >
                Information
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  mt: "50px",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <EmailOutlinedIcon
                    sx={{ color: "#ec0000", fontSize: "18px" }}
                  />
                  <Typography variant="caption" sx={{ fontSize: "18px" }}>
                    Email: info@tazamoni-abtahi.com
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <LocalPhoneOutlinedIcon
                    sx={{ color: "#ec0000", fontSize: "18px" }}
                  />
                  <Typography variant="caption" sx={{ fontSize: "18px" }}>
                    Tel: +982159187000
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <LocationOnOutlinedIcon
                    sx={{ color: "#ec0000", fontSize: "18px" }}
                  />
                  <Typography variant="caption" sx={{ fontSize: "18px" }}>
                    Unit 45 , Floor 1 , Setin Center Complex, Farahzadi Blvd
                    ,Tehran , Iran
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </Layout>
  );
};

export default ContactUs;
