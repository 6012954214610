import React from "react";
import Header from "../components/header/Header";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Layout from "../components/layout/Layout";
import PageContainer from "../components/layout/PageContainer";
import { Link } from "react-router-dom";
import BearingImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/Bearing.jpeg";
import HeavymachineryImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/Heavy machinery spare parts trading.png";
import InjectionImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/Injection pump011.jpg";
import CrankshaftImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/Crankshaft.png";
import DynamoImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/dynamo.jpeg";
import FilterImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/dynamo.jpeg";
import CylinderImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/head cylinder.png";
import HydraulicHammerImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/hydraulic hammers.png";
import PistonImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/piston.jpeg";
import PumpImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/pump.png";
import ThermostatImg from "../assets/Heavy Equipment & Machinery Spare Parts Trading/thermostat-01.jpg";

const HeavyMachinery = () => {
  return (
    <Layout>
      <PageContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            padding: "5px 20px",
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "36px", fontWeight: "bold" }}
            >
              The fertilizer
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
              mt: "50px",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}
            >
              The fertilizer industry transforms millions of tons of air,
              natural gas and mined ores into plant nutrition products based on
              the three essential nutrients nitrogen, phosphorus and potassium.
              But how are fertilizers produced?<br></br>
              Plants need nutrients to grow which they absorb from the soil via
              the plant’s root system. Fertilizers provide the major nutrients
              (nitrogen, phosphorus and potassium and important secondary
              elements) that plants need. Unless the nutrients are replenished,
              the soil’s productive capacity declines with every harvest.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "50px",
              }}
            >
              {/* <Box
                component="img"
                alt="About image"
                src={PumpImg}
                sx={{
                  width: { xs: "100%", sm: "20%" },
                  borderRadius: "10px",
                  mt: "20px",
                }}
              /> */}
              <List
                sx={{ listStyleType: "disc", width: { xs: "100%", sm: "80%" } }}
              >
                <ListItem sx={{ display: "list-item", fontSize: "24px" }}>
                  NITROGEN (N):
                </ListItem>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}
                >
                  For nitrogen-based fertilizers, the largest product group,
                  the process starts by mixing nitrogen from the air with
                  hydrogen from natural gas at high temperature and pressure to
                  create ammonia. Approximately 60% of the natural gas is used
                  as raw material, with the remainder employed to power the
                  synthesis process. <br></br>The ammonia is used to make nitric
                  acid, with which it is then mixed to produce nitrate
                  fertilizers such as ammonium nitrate (AN). Ammonia may also be
                  mixed with liquid carbon dioxide to create urea. Both these
                  products can be further mixed together with water to form UAN
                  (urea ammonium nitrate) solution.<br></br>
                </Typography>
              </List>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "50px",
              }}
            >
              {/* <Box
                component="img"
                alt="About image"
                src={HydraulicHammerImg}
                sx={{
                  width: { xs: "100%", sm: "20%" },
                  borderRadius: "10px",
                  mt: "20px",
                }}
              /> */}
              <List
                sx={{ listStyleType: "disc", width: { xs: "100%", sm: "80%" } }}
              >
                <ListItem sx={{ display: "list-item", fontSize: "24px" }}>
                  PHOSHPORUS (P):
                </ListItem>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}
                >
                  Phosphorus-based fertilizers are produced from mined ores.
                  Phosphate rock is primarily treated with sulphuric acid to
                  produce phosphoric acid, which is either concentrated or mixed
                  with ammonia to make a range of phosphate (P2O5) fertilizers.{" "}
                  <br></br>
                </Typography>
              </List>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "50px",
              }}
            >
              {/* <Box
                component="img"
                alt="About image"
                src={BearingImg}
                sx={{
                  width: { xs: "100%", sm: "20%" },
                  borderRadius: "10px",
                  mt: "20px",
                }}
              /> */}
              <List
                sx={{ listStyleType: "disc", width: { xs: "100%", sm: "80%" } }}
              >
                <ListItem sx={{ display: "list-item", fontSize: "24px" }}>
                  POTASSIUM (K):
                </ListItem>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}
                >
                  Potassium is the third major plant and crop nutrient.
                  Potassium-based fertilizers are also produced from mined ores.
                  Several chemical processes can be used to convert the potash
                  rock into plant food, including potassium chloride, sulphate
                  and nitrate.
                </Typography>
              </List>
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </Layout>
  );
};

export default HeavyMachinery;
