import React from "react";
import Header from "../components/header/Header";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";
import LimestoneImg from "../assets/Raw Material Trading/Limestone-.jpg";
import BoricImg from "../assets/Raw Material Trading/BORIC ACIDE.jpg";
import CitricImg from "../assets/Raw Material Trading/Citric-Acid.jpg";
import FiberglassImg from "../assets/Raw Material Trading/Fiberglass 01.jpg.png";
import GypsumImg from "../assets/Raw Material Trading/Gypsum Powder-.jpg";
import UreaImg from "../assets/Raw Material Trading/UREA01.jpg";
import sodiumImg from "../assets/Raw Material Trading/sodium_bicarbonate.jpg";
import PageContainer from "../components/layout/PageContainer";

const RawMaterials = () => {
  return (
    <Layout>
      <PageContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            padding: "5px 20px",
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "36px", fontWeight: "bold" }}
            >
              Food
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
              mt: "50px",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}
            >
              Wholesale distributor of natural raw materials: food additives,
              food raw materials, natural sweetening substances, food-grade
              vegetable oils.<br></br> All our products for the food industry
              are manufactured according to the highest quality and safety
              standards. They are thoroughly tested and examined to ensure their
              safety and quality. Whatever you need for your food business, we
              have the ingredients that will help you create exceptional
              products.<br></br> Furthermore, we provide raw materials for
              production that are essential for creating diverse food products.
              This includes everything from natural sweeteners and preservatives
              to plant extracts and etc.<br></br>
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    </Layout>
  );
};

export default RawMaterials;
